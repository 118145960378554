import React from "react";
require("dotenv").config();

function Contact() {
  const emailURL = "https://send.pageclip.co/" + process.env.REACT_APP_PAGECLIP_KEY + "/website-contact";

  return(
    <div className="container">
      <div className="row content-row">
        <div className="col-lg-6 align-self-center content-col">
          <div className="container-fluid container-center">
            <h1 className="contact-head">Get in touch</h1>
            <br /><br /><br />
            <div className="row">
              <div className="col-3 icon-col">
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/houldup/" className="contact-media-link"><span className="fab fa-instagram" /></a>
              </div>
              <div className="col-3 icon-col">
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/nicholashou/" className="contact-media-link"><span className="fab fa-linkedin" /></a>
              </div>
              <div className="col-3 icon-col">
                <a target="_blank" rel="noreferrer" href="https://github.com/nick-hou" className="contact-media-link"><span className="fab fa-github" /></a>
              </div>
              <div className="col-3 icon-col">
                <a target="_blank" rel="noreferrer" href="mailto:hounicholas1@gmail.com" className="contact-media-link"><span className="fas fa-envelope" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 align-self-center content-col">
          <div className="container-fluid container-center">
            <form action={emailURL} class="pageclip-form" method="post">
              <label for="contact-name">Name</label>
              <input type="text" id="contactName" name="contactName" placeholder="Your name.." required />
              <label for="contact-name">Best way to get back to you?</label>
              <input type="text" id="contactInfo" name="contactInfo" placeholder="Your email, phone, etc.." required />
              <label for="subject">Message</label>
              <textarea id="message" name="message" placeholder="Write something.." required></textarea>
              <button class="button pageclip-form__submit" type="submit">
                <span>Send</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
