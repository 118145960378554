// import React from 'react';
import Home from "./Home";
import Work from "./Work";
import Contact from "./Contact";


function Body(props) {
  if(props.name === "Home") {return Home()}
  if(props.name === "Work") {return Work()}
  if(props.name === "Contact") {return Contact()}
}

export default Body;
