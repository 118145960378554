import React from "react";

function Home() {
  return(
    <div className="container">
      <div className="row content-row">
        <div className="col-lg-6 align-self-center content-col">
          <div className="container-fluid container-center">
            <img className="biopic" src="/nick-pic.jpg" alt="My headshot" />
          </div>
        </div>
        <div className="col-lg-6 align-self-center content-col">
          <div className="container-fluid container-center">
            <h1>Hi. I'm Nick.</h1>
            <br />
            <p>Thanks for visiting my page. </p>
            <p>I'm a web developer who's passionate about breaking barriers and making opportunities accessible to everyone.</p>
            <p>During the day, I'm a structural design engineer at Bell Flight in Dallas, TX.</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Home;
