import React from "react";

function Work() {
  return(
    <div className="container">
      <div className="container-fluid container-center content-row">
        <h1>Where I've Been</h1>


        <div className="row content-row">
          <div className="col-lg-9 align-self-center text-col">

            <h2>Software Projects</h2>
            <hr />
            <div className="row resume-section">
              <div className="col-sm-3 align-self-center resume-section-title">
                <h3><a target='blank' href="https://justtap.co/">Tap</a></h3>
                <p>October 2020 - present</p>
              </div>
              <div className="col-sm-9 align-self-center">
                <p>All your business content in one place, powered by NFC.</p>
                <br />
                <p>I helped build this site from the ground up, including developing front- and back-end logic, creating an API for a mobile version, and setting up the app to be hosted on AWS.</p>
                <br />
                <p>Back-end logic build in NodeJS and MongoDB, front-end logic uses vanilla JS. AWS technologies include Cloudfront/S3, EC2, and Route 53.</p>
              </div>
            </div>
            <hr />
            <div className="row resume-section">
              <div className="col-sm-3 align-self-center resume-section-title">
                <h3>FreeCodeCamp Certifications</h3>
                <p>March 2021 - present</p>
              </div>
              <div className="col-sm-9 align-self-center">
                <p>Certifications based on coding projects, courtesy of <a target="blank" href="https://freecodecamp.org">freecodecamp.org</a></p>
                <br />
                <p>Completed certificates include <a target="blank" href="https://www.freecodecamp.org/certification/nick-hou/responsive-web-design">responsive web design</a> and <a target="blank" href="https://www.freecodecamp.org/certification/nick-hou/javascript-algorithms-and-data-structures">JavaScript algorithms and data structures</a></p>
                <br />
                <p>Front end development certificate in progress. Projects are hosted on my <a target="blank" href="https://github.com/nick-hou">GitHub page</a></p>
              </div>
            </div>

            <br/>

            <h2>Work Experience</h2>
            <hr />
              <div className="row resume-section">
                <div className="col-sm-3 align-self-center resume-section-title">
                  <h3>Bell Flight</h3>
                  <p>August 2018 - present</p>
                </div>
                <div className="col-sm-9 align-self-center">
                  <h3>Structural Design Engineer</h3>
                  <p>Defining and modeling the structural components on Bell's Nexus and Valor vehicles. I work directly with manufacturing and performance to determine requirements and translate them into 3D models and built parts.</p>
                  <br />
                  <p>I also cofounded a new Asian American employee resource group. We offer cultural events, professional development training, and community service opportunities to all employees.</p>
                </div>
              </div>
            <hr />
            <div className="row resume-section">
              <div className="col-sm-3 align-self-center resume-section-title">
                <h3>Bell Flight</h3>
                <p>August 2017 - July 2018</p>
              </div>
              <div className="col-sm-9 align-self-center">
                <h3>Quality Engineer</h3>
                <p>Streamlined production of rotor blades by automating contour inspection process. Created python wrappers to automate data processing and eliminate human error.</p>
              </div>
            </div>
            <hr />
            <div className="row resume-section">
              <div className="col-sm-3 align-self-center resume-section-title">
                <h3>Textron Aviation</h3>
                <p>July 2016 - July 2017</p>
              </div>
              <div className="col-sm-9 align-self-center">
                <h3>Manufacturing Engineer</h3>
                <p>Diagnosed root causes of manufacturing errors and proposed corrective actions through acquiring, processing, and analyzing metrology data.</p>
              </div>
            </div>

            <br />
            <div className="row">
              <h2>Education</h2>
            </div>
            <hr />
            <div className="resume-section">
              <h3>University of Ilinois at Urbana Champaign</h3>
              <p>M.S. Aerospace Engineering, August 2018</p>
              <p>Certificate, systems engineering</p>
              <br />
              <p>B.S. Aerospace Engineering, May 2016</p>
              <p>Minor, computer science</p>
            </div>

          </div>




          <div className="col-lg-3 align-self-center text-col">
            <h2>Skills</h2>
            <hr />
            <p>React</p>
            <p>NodeJS</p>
            <p>Express</p>
            <p>MongoDB/Mongoose</p>
            <p>Javascript</p>
            <p>HTML/CSS</p>
            <br />
            <p>Python</p>
            <p>Java</p>
            <p>C++</p>
            <p>Matlab</p>
            <p>Mandarin Chinese</p>
            <p>French</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work;
