import React from "react";

function Footer() {
  return(
    <div class="footer-padding">
      <footer class="footer fixed-bottom">
      <p>© NICHOLAS HOU</p>
      </footer>
    </div>
  )
}

export default Footer;
