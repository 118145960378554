import React, { useState } from "react";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import './styles.css';


function App() {
  const [content, setContent] = useState("Home");

  function changeContent(name) {
    setContent(name);
  }

  return (
    <div className="App">
      <Header
        changeContent = {changeContent}
      />
      <Body
        name={content}
      />
      <Footer />
    </div>
  );
}

export default App;
