import React from "react";

function Header(props) {
  function setHome() {
    props.changeContent("Home");
  }
  function setWork() {
    props.changeContent("Work");
  }
  function setContact() {
    props.changeContent("Contact");
  }

  return(
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">
        <button class="navbar-brand link" onClick={setHome}>NICK HOU</button>
        <button class="navbar-toggler link" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul class="nav navbar-nav ml-auto">

            <button class="nav-item nav-link link" onClick={setHome}>HOME</button>
            {/* <a class="nav-item nav-link" href="/about">ABOUT</a> */}
            <button class="nav-item nav-link link" onClick={setWork}>WORK</button>
            {/* <a class="nav-item nav-link" href="/blog">BLOG</a> */}
            <button class="nav-item nav-link link" onClick={setContact}>CONTACT</button>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header;
